import { useState, useContext } from "react";
import { RiCloseFill } from "react-icons/ri"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from "src/provider/API/call-service";
import AppInfoContext from "src/provider/state-manager/appInfoProvider";
import { AbsoluteContainer, AppText, Button, FlexColumn, FlexRow, Form, FormGroup, FormGroupWithIcon, SidePopupContainer } from "src/style"
import { UserTypes, QoreStaffTypes } from "src/model";
import helpers from "src/utils/helpers";
import { FiChevronDown } from "react-icons/fi";


export const CreateUser: React.FC<any> = ({getUsers, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info: {userType}} = useContext(AppInfoContext)
    const [input, setInput] = useState({
        firstName: '', lastName: '', email: '', phoneNumber: '', username: '', isAdminRole: false, role: ''
    })

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (!helpers.validatePhoneNoAll(input.phoneNumber)) return notifier.show('Kindly provide a valid phone number', "validation Response")
        
        const payload = {...input}
        let response

        if (userType === UserTypes.SUPER_ADMIN) {
            // payload.role = 'SA'
            response = await API.createSuperAdmin(payload)
        }
        else {
            // payload.role = (input.isAdminRole ? 'Admin' : 'User')
            response = await API.createInstitutionUser(payload)
        }

        
        if (response) {
            if (getUsers) getUsers()
        }    
        close()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    Create User
                </AppText>
                <Form onSubmit={handleSubmit} topMargin='2'>
                    <FormGroup>
                        <label>First name</label>
                        <input 
                            placeholder="Enter first name" 
                            name='firstName'
                            value={input.firstName}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup>  
                    <FormGroup>
                        <label>Last name</label>
                        <input 
                            placeholder="Enter last name" 
                            name='lastName'
                            value={input.lastName}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup> 
                    <FormGroup>
                        <label>Username</label>
                        <input 
                            placeholder="Enter username" 
                            name='username'
                            value={input.username}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup>   
                    <FormGroupWithIcon>
                        <label>Role</label>
                        <div className="input-with-icon">
                            <select 
                                name='role'
                                value={input.role}
                                onChange={handleInput}
                                required
                            >
                                <option>- Select Role -</option>
                                {roles?.[userType as UserTypes].map((role)=> (<option key={role.value} value={role.value}>{role.label}</option>))}
                            </select>
                            <FiChevronDown className="input-with-icon-tag" />
                        </div>
                    </FormGroupWithIcon> 
                    <FormGroup>
                        <label>Email</label>
                        <input 
                            placeholder="Enter email" 
                            name='email'
                            type='email'
                            value={input.email}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup>  
                    <FormGroup>
                        <label>Phone number</label>
                        <input 
                            placeholder="Enter phone number" 
                            name='phoneNumber'
                            type='number'
                            value={input.phoneNumber}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup> 
                    {/* { userType === UserTypes.ADMIN &&
                        <FlexRow gap="1.5" alignItems="flex-start" justifyContent="flex-start" bottomMargin="2">
                            <AppSpan fontWeight="600" color="#344054">Admin Role</AppSpan>
                            <Switch
                                radius='1.17' bgColor='#E7E7E7' 
                                checkedBgColor='#0D968F' checkedSliderBgColor="#ffffff"
                            >
                                <input 
                                    type="checkbox" 
                                    checked={input.isAdminRole}
                                    onChange={(e) => setInput({...input, isAdminRole: e.target.checked})} 
                                />
                                <div ></div>
                                <span></span>
                            </Switch>
                        </FlexRow> 
                    } */}
                    
                    <FlexRow justifyContent='flex-end'>
                        <Button
                            width='8.1'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Submit
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}

type Role = 'SA' | 'QoreDeveloper' | 'QoreUser' | 'Admin' | 'User'

const roles: Record<UserTypes, {label: string, value: Role}[]> = {
    [UserTypes.SUPER_ADMIN]: [
        {label: 'Qore Admin', value: QoreStaffTypes.ADMIN},
        {label: 'QoreDeveloper', value: QoreStaffTypes.DEVELOPER},
        {label: 'QoreUser', value: QoreStaffTypes.USER}
    ],
    [UserTypes.ADMIN]: [
        {label: 'Institution Admin', value: UserTypes.ADMIN},
        {label: 'Institution User', value: UserTypes.USER}
    ],
    [UserTypes.USER]: [{label: 'Institution User', value: UserTypes.USER}]
}