import axios, { AxiosRequestConfig } from 'axios'
import { url } from 'src/provider/config/constant'


const instance = axios.create({
    baseURL:  url.BASE_URL 
})

instance.interceptors.request.use(
    async (config: AxiosRequestConfig<any>) => {
        const token = await localStorage.getItem('adminAuthToken')
        const institutionData = localStorage.getItem('institutionData');
        const ibInstitutionCode = institutionData ? JSON.parse(institutionData)?.id : null;
        
        if(token) {
            config.headers!.authToken = `${token}`;
             //@ts-ignore
            config.headers ["X-Institution-Code"] = `${ibInstitutionCode}`
        }
        
        return config;
    },

    (err) => {
        return Promise.reject(err)
    }
)

export default instance