import './style.scss'
import { useState, useContext } from 'react';
import utility from 'src/utils/utility'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import { 
    AppText, Button, FlexRow, Form, ScreenContainer 
} from 'src/style';
import { CheckboxSelection } from 'src/component';
import { useEffect } from 'react';
import { AppFeature, UserTypes } from 'src/model';


const FeatureControl = () => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {info: {userType, institutionData}} = useContext(AppInfoContext)
    const [userRole, setUserRole] = useState('')
    const [appFeatures, setAppFeatures] = useState<Array<AppFeature>>([])
    const [selectedFeatureIds, setSelectedFeatureIds] = useState<Array<string>>([])

    useEffect(() => {
        setUserRole(userType)
    }, [userType])

    useEffect(() => {
        handleFetchAppFeatures()
    }, [])

    useEffect(() => {
        if (institutionData?.featureIDs && appFeatures?.length) {
            let data = [...selectedFeatureIds, ...institutionData?.featureIDs]
            data = [...new Set(data)]
            setSelectedFeatureIds(data)
        }
    }, [institutionData?.featureIDs, appFeatures])

    async function handleFetchAppFeatures () {
        const response = await API.getAllowedInstitutionFeatures()
        if (response) {
            const featuresList = []
            for (let feature of response) {
                if (!feature.options) featuresList.push(feature)
                else {
                    let options = feature.options.split(',')
                    for (let option of options) {
                        featuresList.push({
                            ...feature, 
                            id: `${feature.id}:${option}`,
                            name: `${feature.name} (${option})`,
                            initialId: feature.id
                        })
                    }
                }
            }

            setAppFeatures(featuresList)
        }
    }

    function handleSelectedFeatures (val: boolean, info: AppFeature) {
        if (val) setSelectedFeatureIds([...selectedFeatureIds, String(info.id)])
        else {
            let chosenFeaturesIds = [...selectedFeatureIds].filter((item) => item != String(info.id))
            setSelectedFeatureIds(chosenFeaturesIds)
        }
    }

    function shouldDisableCheckbox (item: AppFeature) {
        let shouldDisabled = false

        if (item.options) {
            let formattedId;
            let splittedOptions = item.options.split(',')

            for (let option of splittedOptions) {
                if (selectedFeatureIds.includes(`${item.initialId}:${option}`)) {
                    formattedId = `${item.initialId}:${option}`
                    break;
                }
            }

            if (formattedId && (formattedId != item.id)) shouldDisabled = true
        }

        return shouldDisabled
    }

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const idsToString = selectedFeatureIds.join(',')

        const response = await API.updateFeatureSubscription(idsToString)
    }
    
    return (
        <ScreenContainer>
            <AppText textSize='1.9' color='#101828' fontWeight="700">
                Feature Control
            </AppText>
            <AppText fontWeight='600' topMargin='2' >Select Features to be active on the App</AppText>
            { appFeatures?.length > 0 &&
                <Form topMargin='2'>
                    <FlexRow 
                        justifyContent='flex-start' gap='2' wrap='wrap'
                        className='control-box-container'
                    >
                        {
                            appFeatures?.map((item: AppFeature, index: number) => {
                                return (
                                    <CheckboxSelection
                                        key={index} 
                                        name={item.name}
                                        customClass='feature-control-box'
                                        output={(val: boolean) => handleSelectedFeatures(val, item)}
                                        value={selectedFeatureIds.includes(String(item.id))}
                                        disabled={userRole != UserTypes.ADMIN || shouldDisableCheckbox(item)}
                                    />
                                )
                            })
                        }
                    </FlexRow>
                    { userRole === UserTypes.ADMIN &&
                        <FlexRow justifyContent='flex-end' topMargin='3'>
                            <Button
                                width='8'
                                titleSize='1.4' fontWeight='600'
                                height='4' radius='0.8'
                                bottomMargin='3.5' hoverColor='#0D968F'
                                hoverBgColor='#fff' borderColor='#0D968F'
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </FlexRow>
                    }
                </Form>
            }
        </ScreenContainer>
    )
}

export default utility.routeData('feature-control', 'Feature Control', <FeatureControl/>)