import './style.scss'
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import { AbsoluteContainer, AppLabel, AppSpan, AppText, Button, CustomContainer, FlexRow, FormGroup, FormGroupWithIcon, Switch} from 'src/style'
import { IoIosArrowBack } from "react-icons/io";
import utility from 'src/utils/utility';
import helpers from 'src/utils/helpers';
import { FiChevronDown } from 'react-icons/fi';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import {AppFeature} from "src/model";
import {CheckboxSelection} from "src/component";

const InstitutionDetails = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info: {selectedInstitution, institutionData}} = useContext(AppInfoContext)
    const [institution, setInstitution] = useState<any>(null)
    const [input, setInput] = useState({
        newCustomerOnboardingType: '', existingCustomerOnboardingType: '',
        migrationStartDateTime: '', migrationEndDateTime: '',
        ibInstitutionCode: selectedInstitution.id, alias: selectedInstitution.alias
    })
    const [buildInput, setBuildInput] = useState({
        packageName:'', 
        versionName:'',
        versionCode:'',
        keystorePassword:'',
        keystoreKey:'',
        keystoreAlias:'',
        updatedAt:'', id:''})
    const [defaultDate] = !!buildInput?.updatedAt ? new Date(buildInput?.updatedAt)?.toISOString().split('T') : ''
    const [showPassword, setShowPassword] = useState(false);

    const [appFeatures, setAppFeatures] = useState<Array<AppFeature>>([])
    const [allowedFeatures, setAllowedFeatures] = useState<Array<AppFeature>>([])
    const [selectedFeatureIds, setSelectedFeatureIds] = useState<Array<string>>([])
    console.log("lg", selectedInstitution)

    const getBuildInfo = useCallback(async()=>{
        const response = await API.fetchAndroidBuildInfoByCodeOrDefault(selectedInstitution.id)
        if (response?.isSuccessful) {
            setBuildInput(prev=>({
                ...prev,
                ...response?.data
            }))
        }
    },[API, selectedInstitution.id])

    useEffect(() => {
       ;(async()=>{
        await getBuildInfo()
       })()

    }, [selectedInstitution.id])

    useEffect(() => {
        if (selectedInstitution) {
            setInstitution(selectedInstitution)
            handleFetchInstitution()
        }
    }, [selectedInstitution])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})
    const handleBuildInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => setBuildInput({...buildInput, [e.target.name]: e.target.value})

    async function handleFetchInstitution () {
        const response = await API.getInstitutionById(selectedInstitution.id, false)
        if (response) {
            let [migrationStartDate, migrationEndDate] = ['', '']
            
            if (response.migrationInfo.migrationStartDate) {
                migrationStartDate = response.migrationInfo.migrationStartDate?.split('T')[0]
            }
            if (response.migrationInfo.migrationEndDate) {
                migrationEndDate = response.migrationInfo.migrationEndDate?.split('T')[0]
            }
            
            setInstitution(response)
            setInput({
                ...input, 
                newCustomerOnboardingType: response.newCustomerOnboardingType, 
                existingCustomerOnboardingType: response.existingCustomerOnboardingType,
                migrationStartDateTime: migrationStartDate,
                migrationEndDateTime: migrationEndDate
            })
        }
    }

    async function handleSaveBuildInfo () {
        const {updatedAt, id, ...payload} = buildInput
        await API.saveAndroidBuildInfo(selectedInstitution.id, {...payload, institutionName: selectedInstitution?.name})
    }

    async function handleResetBuildInfo () {
        const response = await API.removeAndroidBuildInfo(selectedInstitution.id)
        if (response?.isSuccessful) {
            const buildResponse = await API.fetchAndroidBuildInfoByCodeOrDefault(selectedInstitution.id)
            setBuildInput(prev=>({
                ...prev,
                ...buildResponse?.data
            }))
        }
    }

    useEffect(() => {
        handleFetchAppFeatures();
        handleFetchAllowedFeatures()
    }, [])

    useEffect(() => {
        if (allowedFeatures?.length) {
            const allowedFeatureIds = allowedFeatures.map((feature) => String(feature.id));
            setSelectedFeatureIds(allowedFeatureIds); 
        }
    }, [allowedFeatures]);

    async function handleFetchAppFeatures () {
        const response = await API.getApplicationFeatures()
        if (response) {
            const featuresList = []
            for (let feature of response) {
               featuresList.push(feature)
            }

            setAppFeatures(featuresList)
        }
    }

    async function handleFetchAllowedFeatures () {
        const response = await API.getAllowedInstitutionFeatures()
        if (response) {
            const featuresList = []
            for (let feature of response) {
                featuresList.push(feature)
            }

            setAllowedFeatures(featuresList)
        }
    }


    function handleSelectedFeatures (val: boolean, info: AppFeature) {
        if (val) setSelectedFeatureIds([...selectedFeatureIds, String(info.id)])
        else {
            let chosenFeaturesIds = [...selectedFeatureIds].filter((item) => item != String(info.id))
            setSelectedFeatureIds(chosenFeaturesIds)
        }
    }


    async function handleUpdate () {
        const response = await API.updateOnboardingFlowAndMigrationInfo({...input, existingCustomerOnboardingType: input.newCustomerOnboardingType})
    }

    async function updateAllowedFeatures (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const idsToString = selectedFeatureIds.join(',')

        const response = await API.updateAllowedInstitutionFeature(idsToString)
    }

    return (
        <>
            <CustomContainer 
                className='profile-container'
            >
                <CustomContainer>
                    <FlexRow justifyContent='space-between' gap='2' wrap='wrap'>
                        <FlexRow 
                            justifyContent='flex-start' gap='1'
                            width='auto' sizeUnit=''
                            onClick={() => navigate(-1)}
                        >
                            <AppSpan textSize='2' cursor='pointer' >
                                <IoIosArrowBack />
                            </AppSpan>
                            <AppText 
                                textSize='3' fontWeight='600' color='#101828' cursor='pointer'
                            >
                                Institution Details
                            </AppText>
                        </FlexRow>
                        <Button
                            width='9'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    </FlexRow>
                    
                    <CustomContainer>
                        <FlexRow
                            bgColor='#ffffff' minHeight='10.6' alignItems='flex-start'
                            radius='0.8' padding='2' justifyContent='flex-start'
                            gap='6' wrap='wrap' borderColor='#EAECF0'
                            shadow='0px 1px 2px 0px #1018280F'
                        >
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Institution</AppText>
                                <AppSpan color='#A098AE'>{institution?.name}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Alias</AppText>
                                <AppSpan color='#A098AE'>{institution?.alias}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Phone Number</AppText>
                                <AppSpan color='#A098AE'>{institution?.correspondencePhone}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Email Address</AppText>
                                <AppSpan color='#A098AE'>{institution?.correspondenceEmail}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Institution Code</AppText>
                                <AppSpan color='#A098AE'>{institution?.institutionCode}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Setup Date</AppText>
                                <AppSpan color='#A098AE'>{institution?.setupDate && helpers.handleDate(institution?.setupDate)}</AppSpan>
                            </CustomContainer>
                        </FlexRow>
                        <CustomContainer
                            topMargin='2' borderColor='#EAECF0'
                            shadow='0px 1px 2px 0px #1018280F'
                            bgColor='#ffffff' minHeight='48.5'
                            padding='2' radius='0.8'
                        >
                            <CustomContainer>
                                <AppLabel 
                                    color='#101828' textSize='1.8' 
                                    fontWeight='700'
                                >
                                    Configuration
                                </AppLabel>
                                <FlexRow 
                                    topMargin='1' gap='2' 
                                    justifyContent='space-between'
                                    alignItems='stretch'
                                    className='institution-config-item'
                                >
                                    <CustomContainer
                                        borderColor='#EAECF0'
                                        shadow='0px 1px 2px 0px #1018280F'
                                        padding='2.5' radius='0.8' minHeight='15.9'
                                        leftPadding='1.7' rightPadding='1.7'
                                        width='49.5' sizeUnit='%'
                                    >
                                        <AppSpan color='#434344'>New Customer Onboarding</AppSpan>
                                        <FormGroupWithIcon bottomMargin='0' topMargin='2'>
                                            <label>Select Process Flow</label>
                                            <div 
                                                style={{height: '4rem', backgroundColor: '#F9FAFB'}}
                                                className="input-with-icon"
                                            >
                                                <select 
                                                    name='newCustomerOnboardingType'
                                                    value={input.newCustomerOnboardingType}
                                                    onChange={handleInput}
                                                    style={{height: '4rem'}}
                                                    autoComplete='off'
                                                >
                                                    <option value="">Select</option>
                                                    <option value="NG">Nigerian Onboarding</option>
                                                    <option value="RFS">Non Nigerian Onboarding</option>
                                                </select>
                                                <FiChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                    </CustomContainer>

                                    <CustomContainer
                                        borderColor='#EAECF0'
                                        shadow='0px 1px 2px 0px #1018280F'
                                        padding='2.5' radius='0.8' minHeight='15.9'
                                        leftPadding='1.7' rightPadding='1.7'
                                        width='49.5' sizeUnit='%'
                                    >
                                        <AppSpan color='#434344'>Existing Customer Onboarding</AppSpan>
                                        <FormGroupWithIcon bottomMargin='0' topMargin='2'>
                                            <label>Select Process Flow</label>
                                            <div 
                                                style={{height: '4rem', backgroundColor: '#F9FAFB'}}
                                                className="input-with-icon"
                                            >
                                                <select 
                                                    name='newCustomerOnboardingType'
                                                    value={input.newCustomerOnboardingType}
                                                    onChange={handleInput}
                                                    style={{height: '4rem'}}
                                                    autoComplete='off'
                                                >
                                                    <option value="">Select</option>
                                                   <option value="NG">Nigerian Onboarding</option>
                                                    <option value="RFS">Non Nigerian Onboarding</option>
                                                </select>
                                                <FiChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>

                            <CustomContainer topMargin='2'>
                                <AppLabel 
                                    color='#101828' textSize='1.8' 
                                    fontWeight='700'
                                >
                                    Migration
                                </AppLabel>
                                <CustomContainer
                                    borderColor='#EAECF0' topMargin='1' 
                                    shadow='0px 1px 2px 0px #1018280F'
                                    padding='2' radius='0.8' minHeight='11.6'
                                    leftPadding='1.7' rightPadding='1.7'
                                >
                                    <FlexRow 
                                        gap='2' 
                                        justifyContent='space-between'
                                        alignItems='stretch'
                                        className='institution-config-item'
                                    >
                                        <FormGroup 
                                            bottomMargin='0' height='4'
                                            width='49.5'
                                        >
                                            <label>Start Date</label>
                                            <input 
                                                name='migrationStartDateTime'
                                                type='date'
                                                style={{color: input.migrationStartDateTime ? '#000000' : '#667085', backgroundColor: '#F9FAFB'}}
                                                value={input.migrationStartDateTime}
                                                onChange={handleInput}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                        <FormGroup 
                                            bottomMargin='0' height='4'
                                            width='49.5'
                                        >
                                            <label>End Date</label>
                                            <input 
                                                name='migrationEndDateTime'
                                                type='date'
                                                style={{color: input.migrationEndDateTime ? '#000000' : '#667085', backgroundColor: '#F9FAFB'}}
                                                value={input.migrationEndDateTime}
                                                onChange={handleInput}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                    </FlexRow>
                                    <FlexRow 
                                        topMargin='2.5' gap="1.5" 
                                        alignItems="flex-start" justifyContent="flex-start"
                                    >
                                        <AppSpan fontWeight="600" color="#344054">Migration Completion</AppSpan>
                                        <Switch
                                            radius='1.17' bgColor='#E7E7E7' 
                                            checkedBgColor='#0D968F' checkedSliderBgColor="#ffffff"
                                        >
                                            <input 
                                                type="checkbox" 
                                                checked={institution?.migrationInfo?.migrationCompleted}
                                            />
                                            <div ></div>
                                            <span></span>
                                        </Switch>
                                    </FlexRow> 
                                </CustomContainer>
                            </CustomContainer>

                            <CustomContainer topMargin='2'>
                                <AppLabel 
                                    color='#101828' textSize='1.8' 
                                    fontWeight='700'
                                >
                                    Other Information
                                </AppLabel>
                                <FlexRow 
                                    topMargin='1' gap='2' 
                                    justifyContent='space-between'
                                    alignItems='stretch'
                                    className='institution-config-item'
                                >
                                    <CustomContainer
                                        borderColor='#EAECF0'
                                        shadow='0px 1px 2px 0px #1018280F'
                                        padding='2.5' radius='0.8' minHeight='15.9'
                                        leftPadding='1.7' rightPadding='1.7'
                                        width='49.5' sizeUnit='%'
                                    >
                                        <AppSpan color='#434344'>Update Details</AppSpan>
                                        <FormGroup 
                                            topMargin="1.5" bgColor='#F9FAFB'
                                        >
                                            <label>Institution Alias</label>
                                            <input 
                                                placeholder="Enter alias" 
                                                name='alias'
                                                value={input.alias}
                                                onChange={handleInput}
                                                required
                                            />
                                        </FormGroup>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>

                        </CustomContainer>


                        <CustomContainer
                            topMargin='2' borderColor='#EAECF0'
                            shadow='0px 1px 2px 0px #1018280F'
                            bgColor='#ffffff' minHeight='48.5'
                            padding='2' radius='0.8'
                        >
                            <CustomContainer topMargin='2'>
                                <AppLabel
                                    color='#101828' textSize='1.8'
                                    fontWeight='700' bottomMargin='2'
                                >
                                    Feature Control
                                </AppLabel>
                            </CustomContainer>

                            <CustomContainer>
                                <FlexRow
                                    justifyContent='flex-start' gap='2' wrap='wrap'
                                    className='control-box-container'
                                >
                                    {
                                        appFeatures?.map((item: AppFeature, index: number) => {
                                            return (
                                                <CheckboxSelection
                                                    key={index}
                                                    name={item.name}
                                                    customClass='feature-control-box'
                                                    output={(val: boolean) => handleSelectedFeatures(val, item)}
                                                    value={selectedFeatureIds.includes(String(item.id))}
                                                    // disabled={userRole != UserTypes.ADMIN || shouldDisableCheckbox(item)}
                                                />
                                            )
                                        })
                                    }
                                </FlexRow>
                                <CustomContainer topMargin='5'>
                                    <FlexRow justifyContent='flex-end'>
                                    <Button
                                        width='20'
                                        titleSize='1.4' fontWeight='600'
                                        height='4' radius='0.8'
                                        bottomMargin='1' hoverColor='#0D968F'
                                        hoverBgColor='#fff' borderColor='#0D968F'
                                        onClick={updateAllowedFeatures}
                                    >
                                        Update Features
                                    </Button>
                                    </FlexRow>
                                </CustomContainer>
                            </CustomContainer>

                        </CustomContainer>


                        <CustomContainer
                            topMargin='2' borderColor='#EAECF0'
                            shadow='0px 1px 2px 0px #1018280F'
                            bgColor='#ffffff' minHeight='48.5'
                            padding='2' radius='0.8'
                        >
                            <CustomContainer topMargin='2'>
                                <AppLabel 
                                    color='#101828' textSize='1.8' 
                                    fontWeight='700' bottomMargin='2'
                                >
                                    Build Settings
                                </AppLabel>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Package Name</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="-----" 
                                    name='packageName'
                                    value={buildInput.packageName}
                                    onChange={handleBuildInput}
                                    autoComplete='off'
                                    required
                                />

                            </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon>
                            <label>Version Name</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='versionName'
                                    value={buildInput.versionName}
                                    onChange={handleBuildInput}
                                    autoComplete='off'
                                    required
                                />

                            </div>
                        </FormGroupWithIcon>
                    </FlexRow>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Version Code</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='versionCode'
                                    value={buildInput.versionCode}
                                    onChange={handleBuildInput}
                                    autoComplete='off'
                                    required
                                />

                            </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon>
                        <label>Keystore Password</label>
                        <div className="input-with-icon">
                            <input 
                                placeholder="XXXXXXX" 
                                name='keystorePassword'
                                type={showPassword ? 'text' : 'password'}
                                value={buildInput.keystorePassword}
                                onChange={handleBuildInput}
                                autoComplete='off'
                                required
                            />
                            { !showPassword ?
                                <AbsoluteContainer bottom='2' right='3'>
                                    <AppSpan 
                                        color='rgba(0, 0, 0, 0.5)' cursor='pointer'
                                        onClick={() => setShowPassword(true)}
                                    >
                                        <BsEyeSlash fontSize='1.7rem' />
                                    </AppSpan>
                                </AbsoluteContainer>
                                :
                                <AbsoluteContainer bottom='2' right='3'>
                                    <AppSpan 
                                        color='rgba(0, 0, 0, 0.5)' cursor='pointer'
                                        onClick={() => setShowPassword(false)}
                                    >
                                        <BsEye fontSize='1.7rem' />
                                    </AppSpan>
                                </AbsoluteContainer>
                            }
                        </div>
                    </FormGroupWithIcon>                
                    </FlexRow>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Keystore Key</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='keystoreKey'
                                    value={buildInput.keystoreKey}
                                    onChange={handleBuildInput}
                                    autoComplete='off'
                                    required
                                />

                            </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon>
                            <label>Keystore Alias</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='keystoreAlias'
                                    value={buildInput.keystoreAlias}
                                    onChange={handleBuildInput}
                                    autoComplete='off'
                                    required
                                    // disabled={}
                                />
                            </div>
                        </FormGroupWithIcon>
                    </FlexRow>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Updated At</label>
                                <div className="input-with-icon">
                                    <input 
                                    name='updatedAt'
                                    type='date'
                                    max={helpers.getInputValidDate()}
                                    value={defaultDate}
                                    onChange={handleBuildInput}
                                    autoComplete="off"
                                />
                                </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon />
                    </FlexRow>  
                            </CustomContainer>
                        <FlexRow justifyContent='flex-end' gap='2'>
                            <Button
                                width='15'
                                titleSize='1.4' fontWeight='600'
                                height='4' radius='0.8' color='#344054'
                                bottomMargin='3.5' hoverColor='#0D968F'
                                bgColor='#fff' borderColor='#ccc'
                                hoverBorderColor='#0D968F' 
                                onClick={handleResetBuildInfo}
                            >
                                Reset to Default
                            </Button>
                            <Button
                                width='16'
                                titleSize='1.4' fontWeight='600'
                                height='4' radius='0.8'
                                bottomMargin='3.5' hoverColor='#0D968F'
                                hoverBgColor='#fff' borderColor='#0D968F'
                                onClick={handleSaveBuildInfo}
                            >
                                Save Build Settings
                            </Button>
                        </FlexRow>    
                        </CustomContainer>
                    </CustomContainer>
                </CustomContainer>
            </CustomContainer>
        </>
    )
}

export default utility.routeData('institution-details', 'Institution Details', <InstitutionDetails/>)