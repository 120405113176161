import { useContext, useEffect, useReducer, useState } from "react";
import {
  AppText,
  CustomContainer,
  FlexRow,
  ScreenContainer,
  Button,
  DropdownMenu,
  DropdownItem,
  GridContainer,
  AppSpan,
  AbsoluteContainer,
} from "../../../style";
import utility from "../../../utils/utility";
import { FaqType, UserTypes } from "src/model";
import { ReactComponent as NoDataIcon } from "src/assets/img/no-data-icon.svg";
import { IoEllipsisVertical } from "react-icons/io5";
import { NewFaq } from "./popups/new-faq";
import VisibilityContext from "src/provider/state-manager/visibilityProvider";
import AppInfoContext from "src/provider/state-manager/appInfoProvider";
import ApiContext from "src/provider/API/call-service";

const initialState = {
  selectedFaqId: null,
  isEdit: false,
  view: false,
  edit: { question: "", answer: "" }
}

const FaqReducer = (state: any, action: any) => {
  switch (action.type) {
    case "create-new":
      return { ...state, view: true };
    case "handle-edit":
      return {
        ...state,
        selectedFaqId: action.payload.id,
        isEdit: true,
        view: true,
        edit: { question: action.payload.question, answer: action.payload.answer },
      };
    case "close":
      return { ...state, view: false }
    default:
      return state;
  }
}

const FAQs = () => {
  const { API } = useContext(ApiContext);
  const [faqs, setFaqs] = useState<FaqType[]>([]);
  const [menuOpen, setMenuOpen] = useState(null);

  const { decisionBox } = useContext(VisibilityContext);
  const { info: { userType }, } = useContext(AppInfoContext);

  const [{ selectedFaqId, isEdit, view, edit }, dispatch] = useReducer(FaqReducer, initialState);
  const toggleMenu = (index: any) => {
    setMenuOpen(menuOpen === index ? null : index);
  };

  async function fetchSAFAQs() {
    const response = await API.getAppFAQs();
    if (response) setFaqs(response);
  }
  async function fetchAdminFAQs() {
    const response = await API.getInstitutionFAQs();
    if (response) {
      setFaqs(response);
    }
  }
  useEffect(() => {
    if (userType === UserTypes.SUPER_ADMIN) {
      fetchSAFAQs();
    } else if (userType === UserTypes.ADMIN) {
      fetchAdminFAQs();
    }
  }, [userType]);

  const createNew = () => {
    dispatch({ type: "create-new" })
  };

  const handleEditClick = (id: any, question: string, answer: string) => {
    dispatch({ type: "handle-edit", payload: { id, question, answer } })
  };

  const close = () => {
    dispatch({ type: "close" })
  }

  const confirmDelete =  (id: any) => {
    decisionBox.show(
      `Are you sure you want to delete this FAQ?`,
       ()=>handleDelete(id),
      decisionBox.hide,
      `Yes, Delete`,
      "No"
    );
  };

  const handleDelete = async (id: number) => {
    if (userType === UserTypes.SUPER_ADMIN) {
      const response = await API.removeAppFAQ(id);
      if (response) { fetchSAFAQs(); close(); }

    } else if (userType === UserTypes.ADMIN) {
      const response = await API.removeInstitutionFAQ(id);
      if (response) { fetchAdminFAQs(); close(); }
      close();
    }
  };

  return (
    <>
      <ScreenContainer>
        <CustomContainer topMargin="3" className="no-submenu-container">
          <FlexRow
            justifyContent="space-between"
            alignItems="flex-start"
            bottomMargin="1"
            gap="1.5"
            wrap="wrap"
          >
            <AppText textSize="3" color="#101828" fontWeight="600">
              Frequently Asked Questions
            </AppText>
            {(userType === UserTypes.ADMIN ||
              userType === UserTypes.SUPER_ADMIN) && (
                <Button
                  width="11.3"
                  titleSize="1.4"
                  fontWeight="600"
                  height="4"
                  radius="0.8"
                  hoverColor="#0D968F"
                  hoverBgColor="#fff"
                  borderColor="#0D968F"
                  onClick={createNew}
                >
                  New FAQ
                </Button>
              )}
          </FlexRow>

          {faqs?.length ? (
            faqs?.map((data, index) => (
              <CustomContainer
                key={index}
                minHeight="14"
                padding="2"
                radius="0.8"
                borderColor="#D0D5DD"
                topMargin="2"
              >
                <CustomContainer width="90" sizeUnit="%">
                  <AppText textSize="1.8" color="#101828" fontWeight="700">
                    {data?.question}
                  </AppText>
                  <AppText
                    color="#101828"
                    textSize="1.6"
                    fontWeight="300"
                    topMargin="2"
                  >
                    {data?.answer}
                  </AppText>
                </CustomContainer>

                <AbsoluteContainer top="1.2" right="2">
                  <AppSpan
                    textSize="2"
                    color="#111111"
                    hoverColor="#F80000"
                    onClick={() => toggleMenu(index)}
                  >
                    <DropdownMenu isOpen={menuOpen === index}>
                      <DropdownItem
                        onClick={() =>
                          handleEditClick(
                            data?.id,
                            data?.question,
                            data?.answer
                          )
                        }
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          confirmDelete(data?.id);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                    <IoEllipsisVertical />
                  </AppSpan>
                </AbsoluteContainer>
              </CustomContainer>
            ))
          ) : (
            <GridContainer
              minHeight="28"
              padding="2"
              radius="0.8"
              borderColor="#D0D5DD"
              topMargin="2"
            >
              <CustomContainer width="auto" sizeUnit="">
                <NoDataIcon className="icon-component" />
                <AppText
                  align="center"
                  color="#101828"
                  textSize="1.6"
                  fontWeight="600"
                >
                  No Questions
                </AppText>
              </CustomContainer>
            </GridContainer>
          )}
        </CustomContainer>
      </ScreenContainer>
      {view && (
        <NewFaq
          close={() => { close() }}
          getFAQs={
            userType === UserTypes.SUPER_ADMIN
              ? fetchSAFAQs
              : userType === UserTypes.ADMIN
                ? fetchAdminFAQs
                : undefined
          }
          isEdit={isEdit}
          faqId={selectedFaqId}
          editQuestion={edit?.question}
          editAnswer={edit?.answer}
        />
      )}
    </>
  );
};

export default utility.routeData("faqs", "Faqs", <FAQs />);
