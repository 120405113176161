import { ReactElement } from "react";

export interface RouteData {
    routeProps: RouteProps;
    name: string;
}

export interface RouteProps {
    path: string;
    element: ReactElement<any, any>
}

export interface Action<T> {
    type: string,
    payload: T
}

export interface KeyValuePayload {
    key: string,
    value: any
}

export interface AuthModel {
    username: string,
    password: string,
}

export interface ValidatorResponse {
    isValidated: boolean,
    message: string
}

export type Feed = {
    id: number,
    webImageUrl: string,
    mobileImageUrl: string,
    linkUrl: string,
    isWebActive: boolean,
    isMobileActive: boolean
}

export type AppFeature = {
    id: number|string,
    name: string,
    options: string|null,
    isActive: boolean,
    initialId?: number,
    optionName?: string
}

export type Login = {
    ibInstitutionCode: number|string,
    userID: string,
    password: string
}

export type User = {
    fullName: string,
    username: string,
    email: string,
    phoneNumber: string,
    isActive: boolean
}

export enum UserTypes {
    ADMIN = 'Admin',
    USER = 'User',
    SUPER_ADMIN = 'SA',
}

export type PaginationInfo = {
    pageNumber: number,
    pageSize: number
}

export interface IOnboardingFlowAndMigrationInfo {
    newCustomerOnboardingType: string,
    existingCustomerOnboardingType: string,
    migrationCompleted: boolean,
    migrationStartDateTime: string,
    migrationEndDateTime: string
}

export type ForgotPasswordInput = {
    ibInstitutionCode: number|string,
    otp: string,
    email: string,
    newPassword: string,
    confirmPassword: string
}

export type ReportResponse = {
    data: any,
    pageCount: number,
    perPage: number,
    isSuccessful: boolean,
    currentPageNumber: number,
    total: number,
    message: string
}

export interface IAuditDetails {
    userID: number,
    action: string,
    model: string,
    loggedAt: string,
    dataBefore: object,
    dateAfter: object
}

export interface IAndroidBuild {
    id: number,
    ibInstitutionCode: number,
    hasPendingBuild: boolean,
    lastBuildUrl: string,
    lastAabBuildUrl: string,
    statusMessage: string,
    currentHash: string,
    buildType: string,
    currentBuildStartDateTime: string,
    currentBuildCompletionDateTime: string,
    lastBuildDateTime: string
}

export type ImgDimensions = {
    width: number,
    height: number
}

export type ImgToBase64Result = {
    baseURL: string,
    dimensions: ImgDimensions
}

export enum QoreStaffTypes {
    ADMIN = 'SA',
    USER = 'QoreUser',
    DEVELOPER = 'QoreDeveloper',
}

export interface FaqType{
    id:string,
    question: string;
    answer: string;
  };

  export interface NewFaqProps {
    close: () => void;
    getFAQs: (() => Promise<void>) | undefined; 
    isEdit: boolean;
    faqId?: number;
    editQuestion?: string,
    editAnswer?: string
}

export const statusMap: Record<number, string> = {
    0: "-",
    1: "Successful",
    2: "Failed",
    3: "Pending",
    4: "Processing",
    5: "Pending Approval",
  };

  export const transactionTyeMap: Record<number, string>  ={
    0: "-",
    1: "Trade",
    2: "Transfer"
  }