import './style.scss'
import { useState, useContext, useEffect } from 'react';
import utility from 'src/utils/utility'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import ApiContext from 'src/provider/API/call-service';
import {
    AbsoluteContainer,
    AppLabel, AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow,
    Form, FormGroup, FormGroupWithIcon, ScreenContainer
} from 'src/style';
import { explain } from 'src/utils/view'
import { FiChevronDown } from "react-icons/fi"
import ReactTooltip from 'react-tooltip'
import { CheckboxSelection, RadioSelection } from 'src/component';
import { UserTypes } from 'src/model';
import helpers from 'src/utils/helpers';
import { RiCloseFill } from 'react-icons/ri';
import UploadIcon from 'src/assets/img/upload-icon.svg'
import { BsEyeSlash, BsEye } from 'react-icons/bs';


const Configuration = () => {
    const { notifier } = useContext(VisibilityContext)
    const { API } = useContext(ApiContext)
    const { info: { userType, institutionData } } = useContext(AppInfoContext)
    const [userRole, setUserRole] = useState('')
    const [input, setInput] = useState({
        showTx: true, correspondenceEmail: '', correspondencePhone: '', privacyPolicyUrl: '', playstoreEmail: '',
        address: '', defaultAccountOpeningProductCode: '', defaultAccountOfficerCode: '', playstorePassword: '',
        ussdCode: '*220*1#', website: '', allowedAccountProductCodes: '27', disallowedAccountProductCodes: '',
        testAccountNumber: '', testAccountCustomerID: '', testAccountUsername: '', showTransactions: true,
        twoFactorThresholdAmount: '', emailSenderID: '', maximumDailyTransactionLimit: '', maximumOneTimeTransactionLimit: '',
        defaultOneTimeTransactionLimit: '', defaultDailyTransactionLimit: '', fixedDepositProductCode: '', inactivityTime: '',
        termsAndConditionsUrl: '', loanPolicyUrl: '', appzoneUsername: '', appzoneAccountNumber: '', appzoneCustomerID: '',
        recovaInstitutionCode: '', stamp: '', stampExtension: '', hideWithdrawableBalance: false, hideAvailableBalance: false,
        ebanqoAgentCode: '', ebanqoUrlPath: '', ebanqoUsername: '', ebanqoPassword: '', useIdemityForAccountLimitChange: false,
        accountLimitIdemity: '', useVerifyMeForOnboarding: false, phoneNumberLength: '', accountNumberLength: '',
        paymentItemIsHidden: false, useAirtimeAsBills: false, hasLiveChat: false, usePINforLogin: false,


    })
    const [imgExtra, setImgExtra] = useState({
        stampName: '', stampUrl: '',
        stampSize: ''
    })
    const [inputsDisabled, setInputsDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])

    useEffect(() => {
        if (institutionData) {
            const {
                correspondenceEmail, correspondencePhone, defaultAccountOpeningProductCode, defaultAccountOfficerCode,
                allowedAccountProductCodes, disallowedAccountProductCodes, testAccountNumber, testAccountCustomerID,
                testAccountUsername, showTransactions, twoFactorThresholdAmount, emailSenderID, maximumDailyTransactionLimit,
                maximumOneTimeTransactionLimit, defaultOneTimeTransactionLimit, defaultDailyTransactionLimit,
                privacyPolicyUrl, termsAndConditionsUrl, loanPolicyUrl, appzoneUsername, appzoneAccountNumber,
                appzoneCustomerID, stamp, hideWithdrawableBalance, hideAvailableBalance, address, ebanqoCredential,
                recovaCredential, useIdemityForAccountLimitChange, accountLimitIdemity, useVerifyMeForOnboarding, phoneNumberLength,
                accountNumberLength, paymentItemIsHidden, useAirtimeAsBills, hasLiveChat, usePINforLogin
            } = institutionData

            setInput({
                ...input, correspondenceEmail, correspondencePhone, defaultAccountOpeningProductCode, defaultAccountOfficerCode,
                allowedAccountProductCodes, disallowedAccountProductCodes, testAccountNumber, testAccountCustomerID,
                testAccountUsername, showTransactions, twoFactorThresholdAmount, emailSenderID, maximumDailyTransactionLimit,
                maximumOneTimeTransactionLimit, defaultOneTimeTransactionLimit, defaultDailyTransactionLimit,
                privacyPolicyUrl, termsAndConditionsUrl, loanPolicyUrl, appzoneUsername, appzoneAccountNumber,
                appzoneCustomerID, stamp, recovaInstitutionCode: recovaCredential?.recovaInstitutionCode,
                hideWithdrawableBalance: !hideWithdrawableBalance ? false : true,
                hideAvailableBalance: !hideAvailableBalance ? false : true,
                ebanqoAgentCode: ebanqoCredential?.agentCode, ebanqoUrlPath: ebanqoCredential?.urlPath,
                ebanqoUsername: ebanqoCredential?.username, ebanqoPassword: ebanqoCredential?.password,
                address, useIdemityForAccountLimitChange: useIdemityForAccountLimitChange ? true : false, accountLimitIdemity,
                useVerifyMeForOnboarding: useVerifyMeForOnboarding ? true : false, phoneNumberLength, accountNumberLength,
                paymentItemIsHidden: paymentItemIsHidden ? true : false, useAirtimeAsBills: useAirtimeAsBills ? true : false,
                hasLiveChat: hasLiveChat ? true : false, usePINforLogin: usePINforLogin ? true : false
            })


            const stampName = stamp?.substring(stamp?.lastIndexOf('/') + 1)?.split('?')[0];

            setImgExtra({
                ...imgExtra, stampUrl: stamp, stampName
            })
        }

        if (userType) {
            setUserRole(userType)
            if (userType != UserTypes.ADMIN) setInputsDisabled(true)
        }
    }, [institutionData, userType])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => setInput({ ...input, [e.target.name]: e.target.value })

    const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value.replace(/,/g, '')
        if (!isNaN(Number(val))) {
            setInput({ ...input, [e.target.name]: val })
        }
    }

    async function handleFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files instanceof FileList) {
            if (!helpers.isValidFileType(e.target.value)) return notifier.show('Only SVG, PNG, JPG, and JPEG files are allowed')

            const extension = helpers.getFileExtension(e.target.value)

            const uploadedFile = await helpers.compressImage(e.target.files[0])
            if (!uploadedFile) return notifier.show('Unable to compress large file size uploaded')

            helpers.convertFileToBase64(uploadedFile, true)
                .then(async (result: any) => {
                    const dimensions = result.dimensions
                    const { isValidResolution, message } = helpers.validateImgResolution(e.target.name, dimensions)

                    if (!isValidResolution) {
                        return notifier.show(message)
                    }

                    setInput({
                        ...input,
                        [e.target.name]: result.baseURL,
                        [e.target.alt]: extension
                    })

                    setImgExtra({
                        ...imgExtra,
                        [`${e.target.name}Name`]: e.target.files![0]?.name,
                        [`${e.target.name}Url`]: URL.createObjectURL(e.target.files![0]),
                        [`${e.target.name}Size`]: (e.target.files![0]?.size / 1024).toFixed(1),
                    })
                })
                .catch(err => {
                    notifier.show(JSON.stringify(err), 'Validation Error')
                })
        }
    }

    const handleInputLength = (e : React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;

        if (Number(value) < 1) value = '';
        if (value.length >= 20) value = value.slice(0, 20);
    
        setInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const { isValidated, message } = helpers.inputValidator(input, [], ['privacyPolicyUrl', 'termsAndConditionsUrl'])
        if (!isValidated) return notifier.show(message)

        const invalidUrlMessage = helpers.validateConfigUrls(input)
        if (invalidUrlMessage) return notifier.show(invalidUrlMessage)

        API.updateConfiguration({
          ...input,
          accountNumberLength: input.accountNumberLength || 10,
          phoneNumberLength: input.phoneNumberLength || 11,
        });
    }


    return (
        <ScreenContainer>
            <AppText textSize='1.9' color='#101828' fontWeight="700">Configuration</AppText>
            <AppText fontWeight='600' topMargin='2' >Fill the details to configure settings</AppText>
            <Form>
                <CustomContainer topMargin='2'>
                    <FormGroupWithIcon>
                        <label>Notification Email</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="ridwan@appzonegroup.com"
                                name='emailSenderID'
                                value={input.emailSenderID}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the notification email`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Contact Email</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Contact Email"
                                name='correspondenceEmail'
                                value={input.correspondenceEmail}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the contact email`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Contact Us Phone Number</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Contact Phone No."
                                name='correspondencePhone'
                                value={input.correspondencePhone}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the contact phone number`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroup>
                        <label>Contact Us Address</label>
                        <textarea
                            placeholder="Enter Address"
                            name='address'
                            value={input.address}
                            onChange={handleInput}
                            autoComplete='off'
                            required
                            disabled={inputsDisabled}
                        />
                    </FormGroup>
                    <FormGroupWithIcon>
                        <label>Website</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Website"
                                name='website'
                                value={input.website}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the MFB website`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>


                    <CustomContainer topMargin='2'>
                        <AppLabel fontWeight='600' textSize='1.3'>Use Verify-Me For Onboarding</AppLabel>
                        <CustomContainer
                         justifyContent='space-between' wrap='wrap' className='checkbox-container'>
                            <CheckboxSelection
                                name='Use Verify-Me'
                                customClass='single-balance-control-box'
                                output={() => setInput((prevInput) => ({
                                    ...prevInput,
                                    useVerifyMeForOnboarding: !prevInput.useVerifyMeForOnboarding
                                }))}
                                value={input.useVerifyMeForOnboarding}
                                disabled={userRole != UserTypes.ADMIN}
                            />
                               <div  className="checkbox-icon">
                                {explain(
                                    `If not selected, customers must verify their identity before initiating any transaction.`, true, '0'
                                )}
                                </div>
                        </CustomContainer>
                    </CustomContainer>

                    <FormGroupWithIcon>
                        <label>Onboarding Account Opening Product Code</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Code"
                                name='defaultAccountOpeningProductCode'
                                value={input.defaultAccountOpeningProductCode}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            {/* <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the notification email`, true, '0'
                                )}
                            </CustomContainer> */}
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Onboarding Account Officer Code</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Code"
                                name='defaultAccountOfficerCode'
                                value={input.defaultAccountOfficerCode}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            {/* <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the account officer code`, true, '0'
                                )}
                            </CustomContainer> */}
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Appzone Account Number</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Account Number"
                                name='appzoneAccountNumber'
                                value={input.appzoneAccountNumber}
                                onChange={handleInput}
                                autoComplete='off'
                                //required
                                disabled={inputsDisabled}
                            />
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Appzone Account Customer ID</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Customer ID"
                                name='appzoneCustomerID'
                                value={input.appzoneCustomerID}
                                onChange={handleInput}
                                autoComplete='off'
                                //required
                                disabled={inputsDisabled}
                            />
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Appzone Account Username</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Username"
                                name='appzoneUsername'
                                value={input.appzoneUsername}
                                onChange={handleInput}
                                autoComplete='off'
                                //required
                                disabled={inputsDisabled}
                            />
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>2FA Transaction Threshold Amount</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Threshold Amount"
                                name='twoFactorThresholdAmount'
                                value={input.twoFactorThresholdAmount ? utility.moneyFormat(input.twoFactorThresholdAmount) : ''}
                                onChange={handleAmount}
                                autoComplete="off"
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the 2FA transaction threshold amount`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Privacy Policy URL</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Privacy URL"
                                name='privacyPolicyUrl'
                                value={input.privacyPolicyUrl}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the link to the privacy policy`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Terms and Condition URL</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter URL"
                                name='termsAndConditionsUrl'
                                value={input.termsAndConditionsUrl}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the link to the terms and condition`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Loan Policy URL</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter URL"
                                name='loanPolicyUrl'
                                value={input.loanPolicyUrl}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the link to the loan policy`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Recova Institution Code</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Recova Institution Code"
                                name='recovaInstitutionCode'
                                value={input.recovaInstitutionCode}
                                onChange={handleInput}
                                autoComplete='off'
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the Recova institution code`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                </CustomContainer>
                <CustomContainer>
                    <AppLabel color='#101828' textSize='1.8' fontWeight='700' bottomMargin='2'>
                        Google Play Store Test Credentials
                    </AppLabel>
                    <FormGroupWithIcon>
                        <label>Account Username</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Test Username"
                                name='testAccountUsername'
                                value={input.testAccountUsername}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the test account username`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Account Number</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Test Account Number"
                                type='number'
                                name='testAccountNumber'
                                value={input.testAccountNumber}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the test account number`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Customer ID</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Customer ID"
                                name='testAccountCustomerID'
                                value={input.testAccountCustomerID}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the test account customer ID`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                </CustomContainer>
                <CustomContainer>
                    <AppLabel color='#101828' textSize='1.8' fontWeight='700' bottomMargin='2'>
                        Fixed Deposit Settings
                    </AppLabel>

                    {/* <FormGroupWithIcon>
                        <label>Fixed Deposit Product Code</label>
                        <div className="input-with-icon">
                            <select 
                                placeholder="Select product"
                                name='fixedDepositProductCode'
                                value={input.fixedDepositProductCode}
                                onChange={handleInput}
                                autoComplete='off'
                                //required
                                disabled={inputsDisabled}
                            >
                                <option value="">Select product</option>
                            </select>
                            <CustomContainer width='auto' sizeUnit='' className="select-explainer" >
                                {explain(
                                    `This is the fixed deposit product code`, true, '0'
                                )}
                            </CustomContainer>
                            <FiChevronDown className="input-with-icon-tag" />
                        </div>
                    </FormGroupWithIcon> */}
                    <FormGroupWithIcon>
                        <label>Fixed Deposit Product Code</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Code"
                                name='fixedDepositProductCode'
                                value={input.fixedDepositProductCode}
                                onChange={handleInput}
                                autoComplete='off'
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the fixed deposit product code`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Disallowed Product Codes</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Code separated by comma"
                                name='disallowedAccountProductCodes'
                                value={input.disallowedAccountProductCodes}
                                onChange={handleInput}
                                autoComplete='off'
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `Thses are product codes that should be disallowed. it should be separated by comma`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                </CustomContainer>

                <CustomContainer>
                    <AppLabel color='#101828' textSize='1.8' fontWeight='700' bottomMargin='2'>
                        Transaction Limit Settings
                    </AppLabel>

                    <FormGroupWithIcon>
                        <label>Default One Time Transaction Limit</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Limit"
                                name='defaultOneTimeTransactionLimit'
                                value={input.defaultOneTimeTransactionLimit ? utility.moneyFormat(input.defaultOneTimeTransactionLimit) : ''}
                                onChange={handleAmount}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the default one time transaction limit`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Default Daily Transaction Limit</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Limit"
                                name='defaultDailyTransactionLimit'
                                value={input.defaultDailyTransactionLimit ? utility.moneyFormat(input.defaultDailyTransactionLimit) : ''}
                                onChange={handleAmount}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the default daily transaction limit`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Maximum One Time Transaction Limit</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Limit"
                                name='maximumOneTimeTransactionLimit'
                                value={input.maximumOneTimeTransactionLimit ? utility.moneyFormat(input.maximumOneTimeTransactionLimit) : ''}
                                onChange={handleAmount}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the maximum one time transaction limit`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Maximum Daily Transaction Limit</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Limit"
                                name='maximumDailyTransactionLimit'
                                value={input.maximumDailyTransactionLimit ? utility.moneyFormat(input.maximumDailyTransactionLimit) : ''}
                                onChange={handleAmount}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the maximum daily transaction limit`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Inactivity Timeout Duration</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter duration"
                                name='inactivityTime'
                                type='number'
                                value={input.inactivityTime}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the inactivity timeout duration`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>

                    <CustomContainer topMargin='2'>
                        <AppLabel fontWeight='600' textSize='1.3'>Indemnity Settings</AppLabel>
                        <CustomContainer
                            justifyContent='space-between' wrap='wrap'
                            className='control-box-container'
                        >
                            <CheckboxSelection
                                name='Use Indemnity'
                                customClass='single-balance-control-box'
                                output={() => setInput((prevInput) => ({
                                    ...prevInput,
                                    useIdemityForAccountLimitChange: !prevInput.useIdemityForAccountLimitChange
                                }))}
                                value={input.useIdemityForAccountLimitChange}
                                disabled={userRole != UserTypes.ADMIN}
                            />

                            {input.useIdemityForAccountLimitChange && (
                                <FormGroup>
                                    <label>Indemnity Declaration</label>
                                    <textarea
                                        placeholder="Enter Account Limit Indemnity"
                                        name='accountLimitIdemity'
                                        value={input.accountLimitIdemity}
                                        onChange={handleInput}
                                        autoComplete='off'
                                        required={false}
                                        disabled={inputsDisabled}
                                        maxLength={1000}

                                    />
                                </FormGroup>
                            )}

                        </CustomContainer>
                    </CustomContainer>

                    <CustomContainer bottomMargin='1'>
                        <AppLabel color='#344054' fontWeight='600' bottomMargin='0.8'>Show Transactions</AppLabel>
                        <RadioSelection
                            option1='Show'
                            option2='Do not show'
                            disabled={inputsDisabled}
                            output={(val: any) => setInput({ ...input, showTransactions: val === 'Show' })}
                        />
                    </CustomContainer>
                </CustomContainer>
                <CustomContainer>
                    <AppLabel color='#101828' textSize='1.8' fontWeight='700'>
                        Statement Settings
                    </AppLabel>

                    <CustomContainer topMargin='2'>
                        <AppLabel fontWeight='600' textSize='1.3'>Statement stamp and signature</AppLabel>
                        {!input.stamp ?
                            <FlexColumn
                                borderColor='#EAECF0' radius='0.8' minHeight='13' topMargin='0.7'
                                padding='1.5'
                            >
                                <img src={UploadIcon} />
                                <input
                                    type="file" name='stamp' alt='stampExtension'
                                    onChange={handleFile}
                                    className="logo-input"
                                    id="stamp"
                                    disabled={inputsDisabled}
                                />
                                <AppLabel color='#0A756F' fontWeight='600' topMargin='1' htmlFor='stamp' align='center'>
                                    Click to upload image of statement stamp and signature
                                </AppLabel>
                                <AppSpan color='#667085' textSize='1.2' topMargin='0.3'>PNG Preferred</AppSpan>
                            </FlexColumn>
                            :
                            <CustomContainer
                                borderColor='#0D968F' radius='0.8'
                                padding='1.5' minHeight='13' topMargin='0.7'
                                bgColor={!inputsDisabled ? 'transparent' : '#F7F8F9'}
                            >
                                <FlexRow
                                    justifyContent='space-between'
                                    gap='2' bottomMargin='1'
                                >
                                    <CustomContainer width='6.8' height='3.1'>
                                        <img
                                            src={imgExtra.stampUrl} className='ads-img'
                                            style={{ objectFit: 'contain', objectPosition: 'left' }}
                                        />
                                    </CustomContainer>
                                    {!inputsDisabled &&
                                        <AppSpan
                                            hoverColor='#B42318' cursor='pointer'
                                            onClick={() => setInput({ ...input, stamp: '' })}
                                        >
                                            <RiCloseFill fontSize='2rem' />
                                        </AppSpan>
                                    }
                                </FlexRow>
                                <AppText fontWeight='600'>{imgExtra.stampName}</AppText>
                                {imgExtra.stampSize &&
                                    <AppSpan color='#667085'>{imgExtra.stampSize} KB</AppSpan>
                                }
                            </CustomContainer>
                        }
                    </CustomContainer>
                </CustomContainer>
                <CustomContainer topMargin='2'>
                    <AppLabel color='#101828' textSize='1.8' fontWeight='700'>
                        Balance Display Settings
                    </AppLabel>

                    <CustomContainer topMargin='2'>
                        <AppLabel fontWeight='600' textSize='1.3'>Show available balance and withdrawable balance</AppLabel>
                        <FlexRow
                            justifyContent='space-between' wrap='wrap'
                            className='control-box-container'
                        >
                            <CheckboxSelection
                                name='Available balance'
                                customClass='balance-control-box'
                                output={(val: boolean) => setInput({ ...input, hideAvailableBalance: !val })}
                                value={!input.hideAvailableBalance}
                                disabled={userRole != UserTypes.ADMIN || (input.hideWithdrawableBalance && !input.hideAvailableBalance)}
                            />
                            <CheckboxSelection
                                name='Withdrawable balance'
                                customClass='balance-control-box'
                                output={(val: boolean) => setInput({ ...input, hideWithdrawableBalance: !val })}
                                value={!input.hideWithdrawableBalance}
                                disabled={userRole != UserTypes.ADMIN || (input.hideAvailableBalance && !input.hideWithdrawableBalance)}
                            />
                        </FlexRow>
                    </CustomContainer>
                </CustomContainer>
                <CustomContainer topMargin='2'>
                    <AppLabel
                        color='#101828' textSize='1.8'
                        fontWeight='700' bottomMargin='2'
                    >
                        Live Chat (Ebanqo Settings)
                    </AppLabel>

                    <FormGroupWithIcon>
                        <label>Agent Code</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Code"
                                name='ebanqoAgentCode'
                                value={input.ebanqoAgentCode}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the ebanqo agent code`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Webchat URL Path</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter URL"
                                name='ebanqoUrlPath'
                                value={input.ebanqoUrlPath}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the ebanqo webchat url`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Username</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Username"
                                name='ebanqoUsername'
                                value={input.ebanqoUsername}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the ebanqo username`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>Password</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="XXXXXXX"
                                name='ebanqoPassword'
                                type={showPassword ? 'text' : 'password'}
                                value={input.ebanqoPassword}
                                onChange={handleInput}
                                autoComplete='off'
                                required
                                disabled={inputsDisabled}
                            />
                            {!showPassword ?
                                <AbsoluteContainer bottom='2' right='3'>
                                    <AppSpan
                                        color='rgba(0, 0, 0, 0.5)' cursor='pointer'
                                        onClick={() => setShowPassword(true)}
                                    >
                                        <BsEyeSlash fontSize='1.7rem' />
                                    </AppSpan>
                                </AbsoluteContainer>
                                :
                                <AbsoluteContainer bottom='2' right='3'>
                                    <AppSpan
                                        color='rgba(0, 0, 0, 0.5)' cursor='pointer'
                                        onClick={() => setShowPassword(false)}
                                    >
                                        <BsEye fontSize='1.7rem' />
                                    </AppSpan>
                                </AbsoluteContainer>
                            }
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the ebanqo password`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>
                </CustomContainer>



                <CustomContainer topMargin='2'>
                    <AppLabel
                        color='#101828' textSize='1.8'
                        fontWeight='700'
                    >
                        Advanced Settings
                    </AppLabel>
                    <CustomContainer>
                    <AppLabel
                        color='#888' textSize='1.4'
                        fontWeight='700' bottomMargin='2'
                    >
                         Please don’t make changes here unless instructed by our team.
                    </AppLabel>
                    </CustomContainer>
                  

                    <FormGroup>
                        <label>Account number Length</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Account Number Length"
                                name='accountNumberLength'
                                value={input.accountNumberLength}
                                onChange={handleInputLength}
                                autoComplete='off'
                                required={false}
                                disabled={inputsDisabled}
                                type='number'
                                max={20}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <label>Phone Number Length</label>
                        <div className="input-with-icon">
                            <input
                                placeholder="Enter Phone Number Length"
                                name='phoneNumberLength'
                                value={input.phoneNumberLength}
                                onChange={handleInputLength}
                                autoComplete='off'
                                required={false}
                                disabled={inputsDisabled}
                                type='number'
                                max={20}
                            />
                        </div>
                    </FormGroup>
                    <FlexRow
                        justifyContent='space-between' wrap='wrap'
                        className='control-box-container'
                    >
                        <CheckboxSelection
                            name='Hide Payment Items'
                            customClass='balance-control-box'
                            output={() => setInput((prevInput) => ({
                                ...prevInput,
                                paymentItemIsHidden: !prevInput.paymentItemIsHidden
                            }))}
                            value={input.paymentItemIsHidden}
                            disabled={userRole != UserTypes.ADMIN}
                        />
                        <CheckboxSelection
                            name='Airtime as Bills'
                            customClass='balance-control-box'
                            output={() => setInput((prevInput) => ({
                                ...prevInput,
                                useAirtimeAsBills: !prevInput.useAirtimeAsBills
                            }))}
                            value={input.useAirtimeAsBills}
                            disabled={userRole != UserTypes.ADMIN}
                        />
                    </FlexRow>
                    <FlexRow
                        justifyContent='space-between' wrap='wrap'
                        className='control-box-container'
                    >
                        <CheckboxSelection
                            name='Live Chat'
                            customClass='balance-control-box'
                            output={() => setInput((prevInput) => ({
                                ...prevInput,
                                hasLiveChat: !prevInput.hasLiveChat
                            }))}
                            value={input.hasLiveChat}
                            disabled={userRole != UserTypes.ADMIN}
                        />
                        <CheckboxSelection
                            name='Login With Pin'
                            customClass='balance-control-box'
                            output={(val: boolean) => setInput((prevInput) => ({
                                ...prevInput,
                                usePINforLogin: !prevInput.usePINforLogin
                            }))}
                            value={input.usePINforLogin}
                            disabled={userRole != UserTypes.ADMIN}
                        />
                    </FlexRow>

                </CustomContainer>

                {userRole === UserTypes.ADMIN &&
                    <FlexRow justifyContent='flex-end' topMargin='3'>
                        <Button
                            width='8'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='3.5' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </FlexRow>
                }
            </Form>
        </ScreenContainer>
    )
}

export default utility.routeData('configuration', 'Configuration', <Configuration />)